import type { TBackgroundColor, TCurrencyType, TPossibleNull } from '~/types/Shared.types';

/* Список типов счётчика */
export enum ECasesCounterTypes {
  QUANTITY = 'quantity',
  TIME = 'time',
}

/* Данные о счётчике кейса */
export interface ICasesItemCounter {
  end: number;
  start: number;
  type: ECasesCounterTypes;
}

/* Данные о лейбле кейса */
export interface ICasesItemLabel {
  background: TBackgroundColor;
  color: TBackgroundColor;
  text: string;
}

/* Данные о стоимости кейса */
export interface ICasesItemPrice {
  coins: number;
  currency: TCurrencyType;
  old: number;
  price: number;
}

/* Данные о кейсе */
export interface ICasesItem {
  coinsData: TPossibleNull<number[] | number>;
  counterData: TPossibleNull<ICasesItemCounter>;
  disabled: boolean;
  id: number;
  image: string;
  labelData: TPossibleNull<ICasesItemLabel>;
  level?: number;
  link: string;
  locked?: boolean;
  name: string;
  priceData: Partial<ICasesItemPrice>;
}

/* Данные о цветах */
export interface ICasesColorData {
  background: string;
  circle: string;
  hover: string;
  shadow: string;
}
