import type { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables';
import type {
  ICasesApiCoinItem,
  ICasesApiColorData,
  ICasesApiItem,
  ICasesApiItemCoins,
  ICasesApiItemPrice,
  ICasesApiItemStatistic,
} from './api.types';
import {
  ECasesCounterTypes,
  type ICasesColorData,
  type ICasesItemCounter,
  type ICasesItemLabel,
  type ICasesItemPrice,
} from './client.types';

import { Colors } from '~/constants/colors.constants';
import type { TPossibleNull } from '~/types/Shared.types';
import type { IItemCaseData } from '~/features/cases/types/case.types';

export const getCoinLevel = (level: number) => {
  if (level > 3) return (level + 1) * 15;

  const levels = [1, 15, 30, 40];
  return levels[level];
};

export const getCoinsData = (coins: ICasesApiItemCoins): TPossibleNull<number[] | number> => {
  const result = [];
  if (coins.min) result.push(coins.min);
  if (coins.max) result.push(coins.max);

  if (result.length === 0) return null;
  if (result.length === 1) return result[0];
  return result;
};

export const getCounterData = (statistic: ICasesApiItemStatistic): TPossibleNull<ICasesItemCounter> => {
  if (statistic.totalCount === 0) return null;
  // TODO добавить обработку счётчика в виде времени

  return {
    end: statistic.openedCount,
    start: statistic.totalCount,
    type: ECasesCounterTypes.QUANTITY,
  };
};

export const getImage = (image: TPossibleNull<string>): string => {
  if (!image) return '';
  if (!image.includes('https')) return `${useLinks().domen}${image}`;
  return image;
};

export const getLabelData = (
  item: ICasesApiItem | ICasesApiCoinItem | IItemCaseData,
  t: ComposerTranslation,
): TPossibleNull<ICasesItemLabel> => {
  const isPromoCase = item.name === 'cs_case_stage_landing';

  // Лейбл если закончился кейс
  if ('available' in item && !item.available && !isPromoCase) {
    return {
      background: Colors.ADDITIONAL.LIGHT_RED,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.ended'),
    };
  }

  // Лейбл мультификса
  if (item.multifix) {
    return {
      background: Colors.GRADIENTS.PURPLE_PINK,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.multifix', { x: item.multifix }),
    };
  }

  // Лейбл мультикаста
  if (item.multicastData?.range?.length === 2 && item.multicastData?.range?.every((multi) => multi)) {
    const template = { x1: item.multicastData.range[0], x2: item.multicastData.range[1] };
    return {
      background: Colors.GRADIENTS.ORGANGE_RED,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.multicast', template),
    };
  }
  if (item.labelData?.name?.includes('Multicast')) {
    return {
      background: Colors.GRADIENTS.ORGANGE_RED,
      color: Colors.NEUTRAL.WHITE,
      text: item.labelData.name,
    };
  }

  // Лейбл новинки
  if (item.labelData?.type === 'new' || isPromoCase) {
    return {
      background: Colors.ADDITIONAL.DARK_ORANGE,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.new'),
    };
  }

  // Лейбл бесплатных кейсов
  if (item.labelData?.freeOpens) {
    return {
      background: Colors.MAIN.GOLD,
      color: Colors.NEUTRAL.BLACK,
      text: t('casesLabels.free', { count: item.labelData.freeOpens }),
    };
  }

  // Лейбл обновленных кейсов
  if (item.labelData?.type === 'update') {
    return {
      background: Colors.ADDITIONAL.LIGHT_BLUE,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.update'),
    };
  }

  // Лейбл обновленных кейсов
  if (item.labelData?.type === 'bestseller') {
    return {
      background: Colors.ADDITIONAL.LIGHT_GREEN,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.bestseller'),
    };
  }

  // Лейбл кешбек кейсов
  if (item.labelData?.type === 'cashback') {
    return {
      background: Colors.ADDITIONAL.LIGHT_PINK,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.cashback'),
    };
  }

  // Лейбл эксклюзив кейсов
  if (item.labelData?.type === 'exclusive') {
    return {
      background: Colors.ADDITIONAL.VIOLET,
      color: Colors.NEUTRAL.WHITE,
      text: t('casesLabels.exclusive'),
    };
  }

  // Кастомный лейбл
  if (item.labelData?.type === 'custom' && item.labelData.name) {
    return {
      background: GlobalUtils.Colors.passColorThroughHash(item.labelData.color ?? Colors.ADDITIONAL.DARK_ORANGE),
      color: Colors.NEUTRAL.WHITE,
      text: item.labelData.name,
    };
  }

  return null;
};

export const getPriceData = (price: ICasesApiItemPrice): Partial<ICasesItemPrice> => {
  if (price.currency === 'COIN') {
    return {
      coins: +price.price || 0,
    };
  }

  return {
    currency: price.currency,
    old: +price.oldPrice || 0,
    price: +price.price || 0,
  };
};

export const getColorData = (color: ICasesApiColorData): TPossibleNull<ICasesColorData> => {
  if (Object.values(color).every((item) => !item)) return null;

  const result: ICasesColorData = {
    background: '',
    circle: '',
    hover: '',
    shadow: '',
  };

  const caseBackground = Colors.MAIN.PURPLE_600;
  const caseCircle = 'radial-gradient(50% 50% at 50% 50%, #331862 87.5%, #8e60ee 100%)';
  const caseHover = Colors.MAIN.PURPLE_400;
  const caseShadow = Colors.MAIN.PURPLE_100;
  const white = Colors.NEUTRAL.WHITE.toUpperCase();

  if (color.gradient && color.gradient.length > 1) {
    if (color.gradient[0] === white && color.gradient[1] === white) {
      result.background = GlobalUtils.CSS.getBackgroundColor({
        colorStops: [caseBackground, caseBackground],
        toDirection: '180deg',
      });
    } else {
      result.background = GlobalUtils.CSS.getBackgroundColor({
        colorStops: color.gradient,
        toDirection: '180deg',
      });
    }
  }

  if (color.circle && color.circle.length === 2) {
    if (color.circle[0] === white && color.circle[1] === white) {
      result.circle = caseCircle;
    } else {
      result.circle = `radial-gradient(50% 50% at 50% 50%, ${color.circle[0]} 87.5%, ${color.circle[1]} 100%)`;
    }
  }

  if (color.gradientHovered && color.gradientHovered.length > 1) {
    if (color.gradientHovered[0] === white && color.gradientHovered[1] === white) {
      result.hover = GlobalUtils.CSS.getBackgroundColor({
        colorStops: [caseHover, caseHover],
        toDirection: '180deg',
      });
    } else {
      result.hover = GlobalUtils.CSS.getBackgroundColor({
        colorStops: color.gradientHovered,
        toDirection: '180deg',
      });
    }
  }

  if (color.circleShadow) {
    if (color.circleShadow === white) {
      result.shadow = caseShadow;
    } else {
      result.shadow = color.circleShadow;
    }
  }

  return result;
};
